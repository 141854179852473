/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          // padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            borderTop: `1px solid #ded1be`,
            width: `100%`,
            marginBottom: `3rem`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              margin: `2rem auto`,
            }}
          >
            <div style={{ width: "50px", height: "50px", marginRight: "3em" }}>
              <a
                href="https://www.facebook.com/senseandvulnerability/"
                style={{
                  // color: `#831911`,
                  textDecoration: `none`,
                  margin: `0 4rem 0 0`,
                  fontSize: "3rem",
                }}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 818.38 818.38"
                >
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="409.19"
                      y1="818.38"
                      x2="409.19"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stop-color="#0054d4" />
                      <stop offset="1" stop-color="#008be9" />
                    </linearGradient>
                  </defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="_ÎÓÈ_1" data-name="—ÎÓÈ_1">
                      <rect
                        width="818.38"
                        height="818.38"
                        rx="405.1"
                        fill="url(#linear-gradient)"
                      />
                      <path
                        d="M637.68,556.66V448.16H509.85V359q0-21.83,9.35-35.54t27.44-13.72h91v-121H544.15q-82.32,0-121.91,42.71T382.65,360.24v87.92H314.06v108.5h68.59V817.75c7.43.4,14.91.63,22.45.63h8.18a406,406,0,0,0,96.57-11.62V556.66Z"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
            <div style={{ width: "50px", height: "50px" }}>
              <a
                href="https://www.instagram.com/senseandvulnerability/"
                style={{
                  // color: `#831911`,
                  textDecoration: `none`,
                  fontSize: "3rem",
                }}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 796.18 796.18"
                >
                  <defs>
                    <linearGradient
                      id="linear-gradient2"
                      x1="114.84"
                      y1="681.34"
                      x2="681.34"
                      y2="114.84"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stop-color="#fbdb6c" />
                      <stop offset="0.14" stop-color="#fcb145" />
                      <stop offset="0.28" stop-color="#f75256" />
                      <stop offset="0.48" stop-color="#d5368b" />
                      <stop offset="0.81" stop-color="#8339ce" />
                      <stop offset="0.83" stop-color="#7a3ed4" />
                      <stop offset="0.88" stop-color="#6948e0" />
                      <stop offset="0.93" stop-color="#5f4de7" />
                      <stop offset="1" stop-color="#5c4fe9" />
                    </linearGradient>
                  </defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="_ÎÓÈ_1" data-name="—ÎÓÈ_1">
                      <rect
                        width="796.18"
                        height="796.18"
                        rx="392.08"
                        fill="url(#linear-gradient2)"
                      />
                      <path
                        d="M524.1,244.77a28.49,28.49,0,1,0,28.49,28.49A28.52,28.52,0,0,0,524.1,244.77Z"
                        fill="#fff"
                      />
                      <path
                        d="M400,285.16a113.2,113.2,0,1,0,113.2,113.2A113.34,113.34,0,0,0,400,285.16ZM400,469a70.61,70.61,0,1,1,70.61-70.61A70.69,70.69,0,0,1,400,469Z"
                        fill="#fff"
                      />
                      <path
                        d="M526.31,159.79H269.86A110.47,110.47,0,0,0,159.51,270.13V526.59A110.47,110.47,0,0,0,269.86,636.94H526.32A110.47,110.47,0,0,0,636.66,526.59V270.14A110.47,110.47,0,0,0,526.31,159.79ZM269.86,591a64.47,64.47,0,0,1-64.4-64.4V270.13a64.46,64.46,0,0,1,64.4-64.39H526.31a64.47,64.47,0,0,1,64.4,64.4V526.59A64.47,64.47,0,0,1,526.32,591Z"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              color: "#000000",
              fontFamily: "pacifico, sans-serif",
              fontSize: "1.5rem",
            }}
          >
            © {new Date().getFullYear()}, Sense & Vulnerability
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
